const plugin = require('tailwindcss/plugin');
const colorPalette = require('./libs/ui/src/foundation/color-palette');

const blastRadiusTheme = {
  colors: colorPalette,
  letterSpacing: {
    wide: '0.006em',
    wider: '0.016em',
    widest: '0.063em',
    'x-widest': '0.113rem',
  },
  fontFamily: {
    sans: ['Public Sans', 'sans-serif'],
    blast: ['Bai Jamjuree', 'sans-serif'],
    code: ['Source Code Pro', 'monospace'],
  },
  fontSize: {
    '10px': '.625rem',
    '11px': '.688rem',
    '12px': '.75rem',
    '13px': '.813rem',
    '14px': '.875rem',
    '15px': '.938rem',
    '16px': '1rem',
    '17px': '1.063rem',
    '18px': '1.125rem',
    '19px': '1.188rem',
    '22px': '1.375rem',
    '24px': '1.5rem',
    '26px': '1.625rem',
    '28px': '1.75rem',
    '32px': '2rem',
    '40px': '2.5rem',
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extrabold: 800,
  },
  extend: {
    lineHeight: {
      0: '0',
      1: '1',
      3.5: '.875rem',
      4.5: '1.125rem',
      5.5: '1.375rem',
      9: '2.25rem',
      11: '3rem',
    },
    width: {
      0.5: '0.125rem',
      1.5: '0.375rem',
      4.5: '1.125rem',
      13: '3.25rem',
      18: '4.5rem',
      22: '5.5rem',
      'container-lg': '87.5rem',
      150: '37.5rem',
    },
    height: {
      4.5: '1.125rem',
      7: '1.75rem',
      13: '3.25rem',
      18: '4.5rem',
      22: '5.5rem',
    },
    '-left': {
      0.5: '0.125rem',
    },
    boxShadow: {
      sm: '0px 4px 16px rgba(1, 1, 12, 0.39)',
      md: '0px 8px 16px rgba(1, 1, 12, 0.6)',
      '2md': '0px 4px 23px rgba(55, 57, 129, 0.16)',
      lg: '0px 4px 44px rgba(3, 4, 7, 0.8)',
      xl: '0px 4px 80px rgba(31, 36, 159, 0.09)',
      '2xl': '0px 32px 49px rgba(3, 4, 7, 0.2)',
      'scroll-dark': 'inset 0 0 20px 20px rgb(63, 67, 83)',
      'scroll-white': 'inset 0 0 20px 20px rgb(215, 219, 234)',
    },
    borderRadius: {
      'x-sm': '0.2rem',
    },
    padding: {
      1.5: '.375rem',
      5.5: '1.375rem',
      8.5: '2.125rem',
      18: '4.5rem',
    },
    margin: {
      15: '3.75rem',
    },
    borderWidth: {
      3: '.188rem',
    },
    transitionProperty: {
      fade: 'opacity, transform, visibility',
      width: 'width',
      bg: 'background',
      border: 'border-width',
    },
    animation: {
      enter: 'enter 200ms ease-out',
      leave: 'leave 150ms ease-in forwards',
      'slide-enter': 'slide-enter 250ms ease-out both',
      'slide-leave': 'slide-leave 200ms ease-in both',
      hourglass: 'hourglass 1200ms 100ms infinite ease-in-out',
      fade: 'fade 200ms 300ms ease-in forwards',
      'fade-in': 'fade 200ms ease-in-out forwards',
    },
    keyframes: {
      enter: {
        '0%': { transform: 'scale(0.9)', opacity: 0 },
        '100%': { transform: 'scale(1)', opacity: 1 },
      },
      leave: {
        '0%': { transform: 'scale(1)', opacity: 1 },
        '100%': { transform: 'scale(0.9)', opacity: 0 },
      },
      'slide-enter': {
        '0%': { transform: 'translateX(-6.25rem)', opacity: 0 },
        '100%': { transform: 'translateX(4.5rem)', opacity: 1 },
      },
      'slide-leave': {
        '0%': { transform: 'translateX(4.5rem)', opacity: 1 },
        '100%': { transform: 'translateX(-6.25rem)', opacity: 0 },
      },
      hourglass: {
        '0%': { transform: 'none' },
        '50%': { transform: 'none' },
        '100%': { transform: 'rotate(180deg)' },
      },
      fade: {
        '0%': { opacity: 0 },
        '100%': { opacity: 100 },
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      '1.5xl': '1500px',
      '2.5xl': '1800px',
      '2.75xl': '1900px',
      '3xl': '2048px',
      '4xl': '2560px',
    },
    zIndex: {
      60: '60',
      70: '70',
    },
    cursor: {
      initial: 'initial',
    },
    backgroundImage: {
      'radial-gradient-gray':
        'radial-gradient(95.53% 65.64% at 50.07% -1.09%, #242634 0%, #151725 100%)',
      'card-skeleton-loader-dark': `linear-gradient(90deg, ${colorPalette.gray['700']}, ${colorPalette.gray['800']}, ${colorPalette.gray['700']})`,
      'card-skeleton-loader-light': `linear-gradient(90deg, ${colorPalette.gray['100']}, ${colorPalette.gray['200']}, ${colorPalette.gray['100']})`,
    },
  },
};

const plugins = [
  plugin(({ theme, addUtilities }) => {
    const { red, pink, gray } = theme('colors');

    addUtilities({
      '.border-spacing-y': {
        borderSpacing: '0rem 0.25rem',
      },
      '.border-red-to-pink': {
        borderImageSlice: '1',
        borderImageSource: `linear-gradient(to bottom, ${red['500']}, ${pink['500']})`,
      },
      '.moz-appearance-none': {
        '-moz-appearance': 'textfield',
      },
      '.word-break': {
        wordBreak: 'break-word',
      },
    });
  }),
  require('tailwindcss-labeled-groups')(['deep', 'deeper']),
];

module.exports = {
  jit: true,
  darkMode: 'class',
  theme: blastRadiusTheme,
  variants: {
    extend: {
      boxShadow: ['dark'],
      fill: ['dark'],
    },
  },
  safelist: ['text-24px', 'text-28px', 'text-32px', 'leading-1'],
  plugins,
};
