import React from 'react';
import classNames from 'classnames';
import Icon from '../icon';
import Text from '../text';
import Skeleton from 'react-loading-skeleton';

type Colors = 'yellow' | 'gray';

type Props = {
  icon?: React.ComponentProps<typeof Icon>['name'];
  title?: string;
  content: React.ReactNode;
  color?: Colors;
  loading?: boolean;
};

const colorsMapper: {
  [key in Colors]: { borderColor: string; iconColor: string };
} = {
  gray: {
    borderColor: 'border-gray-500',
    iconColor: 'fill-gray-500',
  },
  yellow: {
    borderColor: 'border-yellow-600',
    iconColor: '!fill-yellow-500',
  },
};

const Alert: React.FC<
  Props & Omit<React.HTMLAttributes<HTMLDivElement>, 'content'>
> = ({ icon, title, content, color = 'gray', loading = false, ...props }) => {
  const defaultClassName =
    'flex items-center border-l rounded-sm bg-white dark:bg-gray-900 px-5 py-4';

  const className = classNames(
    defaultClassName,
    props.className,
    colorsMapper[color].borderColor,
  );

  return (
    <div className="flex items-center justify-center w-full">
      <div {...props} className={className}>
        {icon &&
          (!loading ? (
            <Icon
              name={icon}
              className={classNames('mr-2', colorsMapper[color].iconColor)}
              size={20}
            />
          ) : (
            <Skeleton width={20} height={20} className="mr-2" />
          ))}

        {title &&
          (!loading ? (
            <Text type="label-caps" className="mr-4 uppercase">
              {title}
            </Text>
          ) : (
            <Skeleton width={102} height={12} className="mr-4" />
          ))}

        {!loading ? (
          <Text type="body" size="regular">
            {content}
          </Text>
        ) : (
          <Skeleton width={570} height={18} />
        )}
      </div>
    </div>
  );
};

export default Alert;
