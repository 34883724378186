import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import accessTokenInterceptor from '@customer-web-app/domains/shared/api/interceptors/access-token-interceptor';
import {
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from '@customer-web-app/domains/shared/api/interceptors/response-interceptor';
import accessTokenServerInterceptor from '@customer-web-app/domains/shared/api/interceptors/access-token-server-interceptor';

type AxiosInstanceCustom = {
  setBaseURL: (baseURL: string) => AxiosInstance;
  restoreBaseURL: () => AxiosInstance;
};

const defaultBaseURL =
  process.env.NEXT_PUBLIC_BLASTRADIUS_SERVICES_API_BASE_URL;

export function api(config: AxiosRequestConfig) {
  const api: AxiosInstance & Partial<AxiosInstanceCustom> =
    axios.create(config);

  api.interceptors.request.use(accessTokenInterceptor);

  api.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor,
  );

  return api;
}

export function apiServer(context) {
  const api: AxiosInstance & Partial<AxiosInstanceCustom> = axios.create({
    baseURL: defaultBaseURL,
  });

  api.interceptors.request.use(accessTokenServerInterceptor(context));

  api.interceptors.response.use(
    responseSuccessInterceptor,
    responseErrorInterceptor,
  );

  return api;
}

export default api({
  baseURL: defaultBaseURL,
  retry: 5,
} as AxiosRequestConfig & { retry: number });
