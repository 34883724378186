import React from 'react';
import classNames from 'classnames';
import { InputMessage, InputLabel, Icon, Tooltip } from '@blastradius/ui';
import styles from './input-text.module.scss';
import { RefCallBack, UseFormSetValue } from 'react-hook-form';

type CommonProps = {
  error?: boolean | string;
  iconLeft?: React.ComponentProps<typeof Icon>['name'];
  iconRight?: React.ComponentProps<typeof Icon>['name'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClearValue?: UseFormSetValue<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClearPassword?: UseFormSetValue<any>;
  group?: 'first' | 'last';
  locked?: boolean;
  prefix?: string;
  inputClassName?: string;
  errorClassName?: string;
  type?: HTMLInputElement['type'] | 'textarea';
  iconClassName?: string;
  tooltip?: string;
  withExpand?: boolean;
};

export type InputTextProps =
  | (CommonProps & {
      label?: string;
      id: string;
    })
  | (CommonProps & {
      label?: false;
      id?: never;
    });

const metaKeys = [
  'Tab',
  'CapsLock',
  'ShiftLeft',
  'ControlLeft',
  'MetaLeft',
  'AltLeft',
  'ControlRight',
  'ShiftRight',
  'Enter',
  'AltRight',
  'MetaRight',
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
  'ArrowDown',
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
];

const InputText = React.forwardRef<
  RefCallBack,
  InputTextProps &
    (
      | React.InputHTMLAttributes<HTMLInputElement>
      | React.TextareaHTMLAttributes<HTMLTextAreaElement>
    )
>(
  (
    {
      iconLeft,
      iconRight,
      label,
      error,
      id,
      handleClearValue,
      handleClearPassword,
      group,
      locked,
      prefix,
      type = 'text',
      inputClassName = '',
      errorClassName = '',
      iconClassName = '',
      tooltip,
      withExpand = false,
      ...props
    },
    ref,
  ) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    const toggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    const inputWrapperClassName = classNames(
      'flex flex-col relative group',
      props.className,
      [styles.inputText],
      {
        'gap-2': typeof error === 'string' || label,
        'opacity-50': props.readOnly,
        'opacity-50 pointer-events-none': props.disabled,
        'pointer-events-none': locked,
        '!absolute bottom-0 left-0 z-10': withExpand,
        'top-0 h-[34rem]': withExpand && isExpanded,
        'top-auto h-12': withExpand && !isExpanded,
      },
    );

    const labelClassName = classNames(
      'text-gray-500 transition-all ease-in-out duration-200',
      {
        [`group-hover:text-gray-950 dark:group-hover:text-white ${styles.inputTextLabel}`]:
          !props.readOnly,
        'pointer-events-none': props.readOnly || locked,
      },
    );

    const inputTextClassName = classNames(
      `pl-4 bg-white dark:bg-white/[.06]
    border border-solid outline-none text-[.75rem] text-black
    dark:text-white placeholder:text-gray-950/[0.4] placeholder:dark:text-white/[.4]
    transition-all ease-in-out duration-200 appearance-none`,
      inputClassName,
      {
        'h-12': type !== 'textarea' || (withExpand && !isExpanded),
        'h-full': type === 'textarea' || (withExpand && isExpanded),
        rounded: !group,
        'rounded-tl rounded-bl': group === 'first',
        'hover:bg-gray-950/[.04] hover:dark:bg-white/[.12]': !props.readOnly,
        [`border-l-transparent focus:border-l-black 
          dark:border-l-transparent focus:dark:border-l-white rounded-tr rounded-br`]:
          group === 'last',
        '!border-pink-500 focus:!border-pink-500 !bg-pink-500/[.08]': error,
        [`border-gray-950/[.25] dark:border-white/[.25]
          focus:border-black dark:focus:border-white`]: !error,
        'focus:border-gray-950/[.25] dark:focus:border-white/[.25]':
          props.readOnly,
        '!pl-14': iconLeft,
        '!pl-[5.5rem]': prefix,
        'pr-14': handleClearValue,
      },
    );

    const [showClearButton, setShowClearButton] = React.useState(false);

    let passwordOldValue: string;

    function handleOnKeyDown(e: React.KeyboardEvent) {
      if (
        type === 'password' &&
        passwordOldValue &&
        !metaKeys.includes(e.code)
      ) {
        handleClearPassword?.(props.name as string, '');
      }
    }

    function handleOnClick(
      e: React.MouseEvent<
        HTMLInputElement & HTMLTextAreaElement,
        MouseEvent
      > & {
        target: { value: string };
      },
    ) {
      props.onClick?.(e);

      if (type === 'password') {
        passwordOldValue = e.target.value;
      }
    }

    function handleOnChange(
      e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
    ) {
      props.onChange?.(e);
      setShowClearButton(e.target.value.length > 0);

      if (type === 'password' && passwordOldValue && handleClearPassword) {
        passwordOldValue = '';
      }
    }

    return (
      <div data-testid="input-text-wrapper" className={inputWrapperClassName}>
        {label && (
          <InputLabel
            labelColor={labelClassName}
            htmlFor={id}
            className="inline-block"
          >
            {label}
            {tooltip && (
              <Tooltip
                text={tooltip}
                placement="right"
                className="inline-block"
              >
                <Icon
                  name="circle-info"
                  color="fill-gray-500"
                  size={16}
                  className="ml-0.5 mb-0.5"
                />
              </Tooltip>
            )}
          </InputLabel>
        )}

        {iconLeft && (
          <Icon
            name={iconLeft}
            size={24}
            className={classNames(
              'absolute left-4 pointer-events-none',
              iconClassName,
              {
                'top-[2.125rem]': label,
                'top-[calc(50%-0.75rem)]': !label,
              },
            )}
          />
        )}

        {iconRight && (
          <Icon
            name={iconRight}
            size={16}
            className={classNames(
              'absolute !right-4 pointer-events-none',
              iconClassName,
              {
                'top-[2.3rem]': label,
                'top-4': !label,
              },
            )}
          />
        )}

        {prefix && (
          <InputLabel
            labelColor="text-gray-950 dark:text-white"
            htmlFor={id}
            className={classNames('absolute left-4', {
              'top-[calc(50%+0.25rem)]': label,
              'top-[calc(50%-0.4rem)]': !label,
              'top-[calc(50%-1.18rem)]': error,
            })}
          >
            {prefix}
          </InputLabel>
        )}

        {withExpand && (
          <button
            type="button"
            aria-label={isExpanded ? 'Minimize' : 'Expand'}
            onClick={toggleExpand}
            className="absolute right-4 top-[0.75rem] z-30"
          >
            <Icon name={isExpanded ? 'minimize' : 'expand'} size={16} />
          </button>
        )}

        {type !== 'textarea' && (
          <input
            {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
            type={type}
            className={inputTextClassName}
            ref={(e) => {
              if (typeof ref === 'function') {
                (ref as RefCallBack)(e);
              }

              if (handleClearValue) {
                setShowClearButton((e?.value as string)?.length > 0);
              }
            }}
            onClick={handleOnClick}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            id={id}
            autoComplete={type === 'password' ? 'new-password' : 'off'}
            aria-disabled={props.readOnly || props.disabled || locked}
          />
        )}

        {type === 'textarea' && (
          <textarea
            {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            className={classNames(inputTextClassName, 'px-3 py-4 resize-none')}
            ref={(e) => {
              if (typeof ref === 'function') {
                (ref as RefCallBack)(e);
              }

              if (handleClearValue) {
                setShowClearButton((e?.value as string)?.length > 0);
              }
            }}
            onKeyDown={handleOnKeyDown}
            id={id}
            aria-disabled={props.readOnly || props.disabled || locked}
          ></textarea>
        )}

        {handleClearValue && (
          <button
            type="button"
            aria-label="Clear value"
            onClick={() => {
              handleClearValue(props.name as string, '');
              setShowClearButton(false);
            }}
            className={classNames(
              'transition-all duration-200 ease-in-out opacity-0 invisible',
              {
                '!opacity-100 !visible': showClearButton,
              },
            )}
          >
            <Icon
              name="circle-close"
              size={24}
              className={classNames(
                'absolute !right-4 hover:fill-black dark:hover:fill-white transition-colors ease-in-out duration-200',
                {
                  'top-[2.125rem]': label,
                  'top-[calc(50%-0.75rem)]': !label,
                },
              )}
            />
          </button>
        )}

        {typeof error === 'string' && (
          <InputMessage state="error" className={errorClassName}>
            {error}
          </InputMessage>
        )}
      </div>
    );
  },
);

InputText.displayName = 'InputText';

export default InputText;
