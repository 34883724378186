import React from 'react';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import useUserMetadata from '@customer-web-app/domains/users/hooks/use-user-metadata';
import useUsers from '@customer-web-app/domains/users/hooks/use-users';

const UserMetadataProvider = () => {
  const { user } = useAuthentication();
  const { users } = useUsers();
  const { updateUserMetadata } = useUserMetadata();

  const loggedUser = React.useMemo(() => {
    return users?.find(({ id }) => id === user?.id);
  }, [user?.id, users]);

  const userMetadata = loggedUser
    ? JSON.parse(loggedUser?.metadata as string)
    : null;

  React.useEffect(() => {
    if (loggedUser?.id && userMetadata) {
      const weeklyReport = userMetadata.enabledWeeklyReportNotifications;
      const mentions = userMetadata.enabledMentionsNotifications;

      // Check if either property is undefined (not set)
      if (weeklyReport === undefined || mentions === undefined) {
        updateUserMetadata({
          metadata: {
            // Only set to true if it's undefined, otherwise keep the existing value
            enabledMentionsNotifications:
              mentions === undefined ? true : mentions,
            enabledWeeklyReportNotifications:
              weeklyReport === undefined ? true : weeklyReport,
          },
          userID: user?.id,
          withNotification: false,
        });
      }
    }
  }, [loggedUser, userMetadata, updateUserMetadata, user?.id]);

  return null;
};

export default UserMetadataProvider;
