import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';
import useGlobalStore from '@customer-web-app/domains/shared/hooks/use-global-store';
import * as LDClient from 'launchdarkly-js-client-sdk';
import camelCase from 'lodash/camelCase';
import React from 'react';

export enum Versioned {
  v1 = 'v1',
  v2 = 'v2',
  off = 'off',
}

export type NewFeature = {
  releaseDate: string;
  feature: string;
  expirationDays: number;
};

export type InsightsSections = {
  incidentOverview?: boolean;
  noiseReduction?: boolean;
  resourceUtilization?: boolean;
  responseTime?: boolean;
};

// Define the feature flags here
export type FeatureFlags =
  | 'incidentsEnableSummary'
  | 'settingsEnableDataConnectors'
  | 'incidentsEnableStatusChanging'
  | 'settingsEnableEmailNotifications'
  | 'settingsEnableUserManagement'
  | 'settingsEnableSecurity'
  | 'settingsEnableDomainManagement'
  | 'generalTagNewFeatures'
  | 'alertsEnableEndpointAlerts'
  | 'alertsEnableIdentityAlerts'
  | 'connectorsEnableSelfOnboarding'
  | 'falsificationExperimentalActions'
  | 'connectorsEnableEditConnector'
  | 'settingsEnableAllowDeny'
  | 'incidentsEnableCloseFalsePositiveDialog'
  | 'incidentsEnableSummaryByContext'
  | 'notificationsEnableSendManualNotifications'
  | 'incidentsEnableCondensedSummary'
  | 'incidentsEnableTaskExecution'
  | 'notificationsEnableCreateTemplate'
  | 'settingsEnableCredentials'
  | 'settingsEnableActionConnectors'
  | 'alertsEnableEscalateNetwork'
  | 'incidentsEnableCommentsSidePanel'
  | 'settingsEnableMessageNotificationsScreen'
  | 'notificationsCcsfSpecificNotifications'
  | 'workflowsEnablePage'
  | 'alertsEnableNetworkAlerts'
  | 'experimentalMarkAlertAsBenign'
  | 'generalEnableSideNavigationSecondLayer'
  | 'connectorsEnableConnectorStatus'
  | 'insightsEnableInsights'
  | 'incidentsEnableBulkOperations'
  | 'alertsEnableCloudAlerts'
  | 'pocDisableUserCreation'
  | 'alertsEnableTriageRequiredAlertStatus'
  | 'alertsEnablePriorityFilter'
  | 'alertsEnableSortDropdown'
  | 'alertsEnableDataExplorer'
  | 'alertsEnableEscalateGenericAlert'
  | 'connectorsEnableMicrosoftAdManualUpload'
  | 'notebooksEnablePage'
  | 'generalSystemLearningEnablePage'
  | 'settingsEnableExceptions'
  | 'incidentsEnableDataExplorerByType'
  | 'logsEnablePage'
  | 'allowDenyEnableTrustedApplications'
  | 'dataExplorerEnableFollowUpQuestions'
  | 'allowDenyEnableTrustedCloudEnvironments'
  | 'generalEnableUsersnap';

export type FeatureFlagsStructure = {
  [key in FeatureFlags]:
    | boolean
    | string[]
    | Versioned
    | NewFeature[]
    | InsightsSections;
};

// Define a default value in case of having any issue from LaunchDarkly
// Don't forget to set as true, when the feature is concluded and running in PRODUCTION.
export const flagsDefaultValues: FeatureFlagsStructure = {
  falsificationExperimentalActions: false, // This flag is only for Radiant Security do not change it to true even after the deploy
  incidentsEnableSummary: true,
  settingsEnableDataConnectors: true,
  incidentsEnableStatusChanging: true,
  settingsEnableEmailNotifications: true,
  settingsEnableUserManagement: true,
  settingsEnableSecurity: true,
  settingsEnableDomainManagement: false,
  generalTagNewFeatures: [],
  alertsEnableEndpointAlerts: true,
  alertsEnableIdentityAlerts: false,
  connectorsEnableSelfOnboarding: true,
  connectorsEnableEditConnector: true,
  settingsEnableAllowDeny: true,
  incidentsEnableCloseFalsePositiveDialog: true,
  incidentsEnableSummaryByContext: ['phishing', 'endpoint'],
  notificationsEnableSendManualNotifications: true,
  incidentsEnableCondensedSummary: true,
  incidentsEnableTaskExecution: true,
  notificationsEnableCreateTemplate: true,
  settingsEnableCredentials: true,
  settingsEnableActionConnectors: true,
  alertsEnableEscalateNetwork: true,
  incidentsEnableCommentsSidePanel: true,
  settingsEnableMessageNotificationsScreen: true,
  notificationsCcsfSpecificNotifications: true,
  workflowsEnablePage: true,
  alertsEnableNetworkAlerts: true,
  experimentalMarkAlertAsBenign: false,
  generalEnableSideNavigationSecondLayer: true,
  connectorsEnableConnectorStatus: false,
  incidentsEnableBulkOperations: true,
  insightsEnableInsights: {
    incidentOverview: true,
    noiseReduction: false,
    resourceUtilization: false,
    responseTime: false,
  },
  alertsEnableCloudAlerts: true,
  pocDisableUserCreation: false,
  alertsEnableTriageRequiredAlertStatus: false,
  alertsEnablePriorityFilter: false,
  alertsEnableSortDropdown: false,
  alertsEnableDataExplorer: false,
  alertsEnableEscalateGenericAlert: false,
  connectorsEnableMicrosoftAdManualUpload: false,
  notebooksEnablePage: false,
  generalSystemLearningEnablePage: false,
  incidentsEnableDataExplorerByType: [],
  logsEnablePage: false,
  allowDenyEnableTrustedApplications: false,
  settingsEnableExceptions: false,
  dataExplorerEnableFollowUpQuestions: false,
  allowDenyEnableTrustedCloudEnvironments: false,
  generalEnableUsersnap: false,
};

const FeatureFlagsProvider: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const { setGlobalStore } = useGlobalStore();
  const { user, id } = useAuthentication();
  const [isLoading, setIsLoading] = React.useState(!!user);

  React.useEffect(() => {
    (async () => {
      try {
        if (user) {
          const client = LDClient.initialize(
            process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
            {
              kind: 'user',
              key: user.id,
              email: user.email,
              name: user.name,
              tenantID: user.tenantId,
              tenant: `${user.tenant.name} (ID: ${user.tenantId.substring(
                0,
                4,
              )}...${user.tenantId.substring(user.tenantId.length - 6)})`,
            },
            {
              baseUrl: '/api/feature-flags/base',
              streamUrl: '/api/feature-flags/stream',
              eventsUrl: '/api/feature-flags/events',
            },
          );

          await client.waitForInitialization();

          // Convert from kebab to camel case
          const rawFlags = client.allFlags();
          const flags = {} as FeatureFlagsStructure;
          Object.keys(rawFlags).forEach((flagKey) => {
            flags[camelCase(flagKey)] = rawFlags[flagKey];
          });

          setGlobalStore('featureFlags', flags);
          setIsLoading(false);
        }
      } catch {
        setGlobalStore('featureFlags', flagsDefaultValues);
        setIsLoading(false);
      }
    })();
  }, [id]);

  return !isLoading && children;
};

export default FeatureFlagsProvider;
