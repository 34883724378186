import React from 'react';
import { ReactComponent as UploadFilesSVGLight } from './assets/file-upload-illustration-light.svg';
import { ReactComponent as UploadFilesSVGDark } from './assets/file-upload-illustration-dark.svg';
import useTheme from '../../hooks/use-theme';
import Text from '../../base-components/text';
import classNames from 'classnames';
import { useWatch } from 'react-hook-form';
import UploadFilesListItem from '../upload-files-list-item';

export type UploadedFile = {
  error?: string;
  fileInstance: File;
};

type Props = {
  maxFileSizeInBytes: number;
  maxFileSizeLabel?: string;
  browseFileLabel?: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any;
  allowedExtensions?: string[];
  multiple?: boolean;
  required?: boolean;
  children?: React.ReactNode;
};

const UploadFiles = React.forwardRef<
  HTMLDivElement,
  Props & React.HTMLAttributes<HTMLDivElement>
>(
  (
    {
      maxFileSizeInBytes,
      maxFileSizeLabel,
      browseFileLabel,
      multiple = false,
      form,
      name,
      allowedExtensions = [],
      children,
      className = '',
      required = false,
      ...props
    },
    ref,
  ) => {
    const [files, setFiles] = React.useState<UploadedFile[]>([]);

    const { theme } = useTheme();

    const values = useWatch({ control: form.control });
    const filesInstances = React.useMemo(() => {
      const formFiles = values[name];

      return formFiles instanceof FileList ? formFiles : [];
    }, [values, name]);

    const defaultClassName =
      'min-h-[calc(100%-14.125rem)] 2xl:min-h-[calc(100%-13rem)]';
    const uploadFilesClassName = classNames(defaultClassName, className);

    React.useEffect(() => {
      const tempFiles = [];

      for (let i = 0; i <= filesInstances.length - 1; i++) {
        const fileInstance = filesInstances[i];

        const fileExtension = fileInstance.name.split('.').pop();

        if (fileInstance.size > maxFileSizeInBytes) {
          tempFiles.push({
            fileInstance,
            error: 'This file is too large',
          });
          continue;
        }

        if (
          allowedExtensions.length > 0 &&
          !allowedExtensions.includes(fileExtension as string)
        ) {
          tempFiles.push({
            fileInstance,
            error: 'This file type is not allowed',
          });
          continue;
        }

        tempFiles.push({ fileInstance });
      }

      const hasErrors = tempFiles.some((file) => file?.error);

      if (hasErrors) {
        form.setError(name, {
          message: 'File with error',
        });
      } else {
        form.clearErrors(name);
      }

      setFiles(tempFiles);
    }, [filesInstances]);

    function removeFile(fileIndex: number) {
      const files = Array.from(filesInstances).filter(
        (_: File, index) => index !== fileIndex,
      );

      form.setValue(name, files);
      setTimeout(() => {
        form.trigger();
      }, 10);
    }

    return (
      <div
        className={uploadFilesClassName}
        ref={ref}
        data-testid="upload-files"
      >
        <div
          className={`flex flex-col w-full min-h-[15.625rem] max-h-full
border-dashed border border-gray-950/[.25] dark:border-white/[.25] rounded-lg`}
        >
          <label
            htmlFor="files-instances"
            className={`w-full min-h-[15.625rem] flex flex-col gap-2 justify-center items-center text-center relative`}
          >
            {theme === 'dark' ? (
              <UploadFilesSVGDark data-testid="upload-files-svg-dark" />
            ) : (
              <UploadFilesSVGLight data-testid="upload-files-svg-light" />
            )}

            <Text
              type="heading"
              size="small"
              className="mt-2"
              aria-label={`Drag and drop or browse file${multiple ? 's' : ''}`}
            >
              Drag and drop or{' '}
              <span className="underline">
                {browseFileLabel ?? `browse file${multiple ? 's' : ''}`}
              </span>
            </Text>

            {maxFileSizeLabel && (
              <Text type="body" size="small" color="text-gray-500">
                Maximum file size {maxFileSizeLabel}
              </Text>
            )}

            <input
              data-testid="upload-files-input"
              type="file"
              id="file"
              className="cursor-pointer absolute top-0 left-0 w-full h-full opacity-0"
              multiple={multiple}
              {...props}
              {...form.register(name, { required })}
            />
          </label>

          {children ??
            (files.length > 0 && (
              <>
                <Text
                  as="div"
                  type="body"
                  size="regular"
                  color="text-gray-500 mb-2"
                  className="px-4"
                >
                  Uploaded File{files.length !== 1 && 's'}
                </Text>
                <ul className="flex flex-col gap-1 max-h-[calc(100%-10rem)] overflow-y-auto px-4 pb-4">
                  {files.map((file, index) => (
                    <UploadFilesListItem
                      key={`${file.fileInstance.name}_${index}`}
                      removeFile={removeFile}
                      file={file}
                      fileIndex={index}
                    />
                  ))}
                </ul>
              </>
            ))}
        </div>
      </div>
    );
  },
);

export default UploadFiles;
